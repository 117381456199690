import { ConnectButton } from "components";
import { useStore } from "context/GlobalState";
import { useMemo } from "react";
import { BrowserRouter as Router,Route,Routes } from "react-router-dom";
import { ThemeProvider,createGlobalStyle } from 'styled-components';
import {
  Error404,MyNFTsScreen
} from "./screens";
import { theme } from "./theme";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-style: normal;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Nunito Sans', sans-serif !important;
    color: ${({ theme }) => theme.colors.black};
  }

  .mx-3{
    margin : auto 0.75rem;
  }

  .my-2{
    margin-top :  0.5rem;
    margin-bottom :  0.5rem;
  }

  .my-4{
    margin : 1rem auto;
  }

  .m-0{
    margin : 0 !important;
  }

  .ui.container.wrapper {
    padding-top: 50px;
  }

  .ui.icon.input>input {
    border-radius: 20px;
  }
  .ui.selection.dropdown {
    border-radius: 20px;
  }
  
  .m-auto{
    margin : auto;
  }

  .ml-1{
    margin-left : 0.25rem;
  }

  .text-right{
    text-align  : right;
  }
  .text-center{
    text-align  : center;
  }
`;

function App() {

  const [{ web3Loadded,accounts }] = useStore();

  const open = useMemo(() => !web3Loadded || (accounts || []).length === 0,[web3Loadded,accounts])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <ConnectButton open={open} />
        <div className="container mt-5">
          <Routes>
            <Route path="/" exact element={<MyNFTsScreen />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
