import { Images } from "../images";
import { ImageWithFallback } from 'components';
import { Image,Card } from 'semantic-ui-react';
import styled from "styled-components";


export const VeraCard = styled(Card)`
  &.ui.card {
    padding: 1rem !important;
    box-shadow: none;
    border-radius: 20px;
    >.image {
      border-radius: 20px !important;
    }
    .background {
      background-size: cover;
      height: 305px;
      width: 100%;
      border-radius: 20px !important;
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #23262F;
      padding-top: 10px;
      margin-bottom: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .subTitle {
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    >:last-child{
      border-radius : inherit !important;
    }

  }
}
`;

export const VeraCardShadow = styled(VeraCard)`
  box-shadow: 0px 24px 48px 0 rgb(0 0 0 / 16%) !important;
`

const TokenLink = styled.a`
    position : absolute;
    right : 10px;
    z-index :1;
    top : 10px;
`

export const OpenSeaNFTItem = ({ item,imgClick }) => (
  <VeraCard>
    <ImageWithFallback
      fallback={Images.card1}
      src={item?.image_preview_url || Images.card1}
      size="medium"
      className="m-auto"
      onClick={() => imgClick && imgClick()}
    />
    <TokenLink target="_blank" href={item.permalink}>
      <Image src={Images.opensea} size="mini" />
    </TokenLink>
    <div>
      <p className="title">
        {item.name || <>&nbsp;</>}
      </p>
      <p className="subTitle">
        {item.description}
      </p>
    </div>
  </VeraCard>
)