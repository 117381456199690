import { APIPath,CommonUtility } from 'utility';
import { BaseService } from './base';

class NFT {

    userNFTs = (reqData) => {
        const url = `${APIPath.assets}?${CommonUtility.objectToParams(reqData)}`;
        return BaseService.openseaAPI(url);
    }

    byTokenAddress = (reqData) => {
        const url = `${APIPath.assets}?${CommonUtility.objectToParams(reqData)}`;
        return BaseService.openseaAPI(url);
    }

    metadata = (url) => {
        return new Promise(async (resolve) => {
            try {
                await CommonUtility.timeoutPromise(1000);
                const result = await BaseService.extenralAPICall(url);
                resolve(result);
            } catch (error) {
                resolve({});
            }
        })
    }

}

const NFTService = new NFT();
Object.freeze(NFTService);
export { NFTService };
