import { useMemo } from "react";
import { Placeholder,Card } from "semantic-ui-react";
import { VeraPlaceholderCard } from './../elements/Common'

export const AppListPlaceholder = ({ rows = 3,itemPerRow = 3 }) => {
    const lines = useMemo(() => Array.from({ length: rows },(_,k) => k),[rows]);
    const columns = useMemo(() => Array.from({ length: itemPerRow },(_,k) => k),[itemPerRow]);

    return (
        <>
            {
                lines.map(line => (
                    <Card.Group key={line} doubling itemsPerRow={itemPerRow} stackable>
                        {columns.map((column) => (
                            <VeraPlaceholderCard key={column}>
                                <Placeholder>
                                    <Placeholder.Image className="image" square />
                                </Placeholder>
                                <Card.Content>
                                    <Placeholder>
                                        <Placeholder.Header>
                                            <Placeholder.Line className="title" length='very short' />
                                            <Placeholder.Line className="subTitle" length='medium' />
                                        </Placeholder.Header>
                                    </Placeholder>
                                </Card.Content>
                            </VeraPlaceholderCard>
                        ))}
                    </Card.Group>
                ))
            }
        </>
    );
}