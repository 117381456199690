import React,{ useState } from 'react';
import { Message,Popup,Container } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../context/GlobalState';
import { AppBorderPrimaryButton } from 'elements';

const Header = styled.header`
  padding : 1em 2em;
`

const AddressButton = styled(AppBorderPrimaryButton)`
  text-overflow: ellipsis;
  width: 150px;
  overflow: hidden;
  text-align: right;
`

const Banner = styled(Message)`
  margin: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
`

export const HeaderBar = () => {

  const [{ web3Loadded,accounts,network,networkShouldBe }] = useStore();
  const [hoverText,setHoverText] = useState('Click to copy')

  const copyToClipboard = () => {
    console.log("HERE")
    const textArea = document.createElement("textarea");
    textArea.value = accounts[0];
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    setHoverText("Copied");
    setTimeout(() => {
      setHoverText('Click to copy')
    },1500);
  };

  return (
    <>
      <Header className="text-right">
        <Container >
          {web3Loadded && (accounts || []).length > 0 &&
            <>
              <AddressButton title={accounts[0]}>
                {accounts[0]}
              </AddressButton>
              <AppBorderPrimaryButton onClick={copyToClipboard}>
                {hoverText}
              </AppBorderPrimaryButton>
            </>
          }
        </Container>
      </Header>
      {web3Loadded && network && networkShouldBe !== network && <Banner color="orange">
        You're viewing data from the {networkShouldBe} network, but your wallet is connected to {network}, please switch your network to {networkShouldBe}
      </Banner>}
    </>
  );
};
