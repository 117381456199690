import { useStore } from "context/GlobalState";
import { useEffect,useState } from "react"
import { ErrorConstant,NFTService } from "../utility";

export const UserNFTListHook = () => {

    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    const [{ accounts }] = useStore();

    const [filter,setFilter] = useState({
        account: '',
        contract_address: -1
    });

    useEffect(() => {
        filterChanged("account",(accounts || []).length > 0 ? accounts[0] : "")
    },[accounts]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const reqParams = {
                    owner: filter.account,
                    offset: 0,
                    limit: 50,
                    order_direction: "desc"
                }
                if (filter.contract_address && filter.contract_address !== -1) {
                    reqParams.asset_contract_address = filter.contract_address
                }
                let result = await NFTService.userNFTs(reqParams);
                setData(result.assets || []);
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }
        if (filter.account) {
            fetchData();
        }
    },[filter]);

    const filterChanged = (key,value) => {
        setFilter({
            ...filter,
            [key]: value
        })
    }

    return { data,loading,error,filter,filterChanged };
}

export const UserNFTDetailsHook = (address,tokenId) => {
    const [data,setData] = useState({});
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                let result = await NFTService.byTokenAddress({
                    owner: address,
                    token_ids: [tokenId],
                    offset: 0,
                });
                if (result.assets || [].length > 0) {
                    setData((result.assets || [])[0]);
                };
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }
        if (address && tokenId) {
            fetchData();
        }
    },[address,tokenId]);

    return { data,loading,error };
}


export const NFTMetadataHook = (nftUrls) => {

    const [data,setData] = useState({});
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const urls = nftUrls.filter(x => !Object.keys(data).includes(x));
                const temp = {};
                for (let i = 0; i < urls.length; i++) {
                    let result = await NFTService.metadata(urls[i])
                    temp[urls[i]] = result;
                }
                setData({
                    ...data,
                    ...temp
                })
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }
        if ((nftUrls || []).length > 0) {
            fetchData();
        }
    },[nftUrls]);

    return { data,loading,error };
}