import { CommonConstant,Protocols } from "utility";

const RENTAL_ADDRESS = {
    [Protocols.ethereum]: process.env.REACT_APP_ETHEREUM_CONTRACT_ADDRESS,
    [Protocols.bsc]: process.env.REACT_APP_BSC_CONTRACT_ADDRESS,
    [Protocols.polygon]: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS,
}


export class ContractUtility {

    static getMyAddresses = () => {
        const myContractAddress = process.env.REACT_APP_MY_CONTRACT_ADDRESS;
        const data = myContractAddress.split(",").map((x) => {
            const temp = x.split(":")
            return {
                value: temp[0],
                text: temp[1]
            }
        })
        data.unshift({
            value: -1,
            text: "All"
        })
        return data;
    }

    static getContractAddress = (protocol) => {

        switch (protocol) {
            case Protocols.ethereum:
            case Protocols.bsc:
            case Protocols.polygon:
                return RENTAL_ADDRESS[protocol]

            default:
                return null;
        }
    }

    static getNetwork = (netId) => {
        switch (netId) {
            case "1":
                return "Main";
            case "2":
                return "Morden";
            case "3":
                return "Ropsten";
            case "4":
                return "Rinkeby";
            case "42":
                return "Kovan";
            default:
                return "Unknown";
        }
    }

    static getRightNetwork = (protocol) => {
        if (CommonConstant.mode === "PRODUCTION") {
            switch (protocol) {
                case Protocols.ethereum:
                    return "Main"
                default:
                    return false;
            }
        } else {
            switch (protocol) {
                case Protocols.ethereum:
                    return "Rinkeby"
                default:
                    return false;
            }
        }
    }
}

