import styled from "styled-components";
import { Card,Image } from 'semantic-ui-react';
import { Images } from "../images";


export const Header = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
`;

export const ContainerFull = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background} !important;
  .topRight {
    top: 0;
    right: 0;
    position: absolute;
  }
  .bottomLeft {
    left: 0;
    bottom: 0;
    position: fixed;
  }
`;


export const VeraPlaceholderCard = styled(Card)`
&.ui.card {
    padding: 11px;
    box-shadow: 0px 24px 48px 0 rgb(0 0 0 / 16%);
    border-radius: 20px;
    >.placeholder {
      border-radius: 20px !important;
      height: 319px;
    }
    .content {
      border-top: none !important;
    }
  }
}
`;

export const CornerLogos = () => (
  <>
    <Image className="topRight" src={Images.logoTopRight} />
    <Image className="bottomLeft" src={Images.logoBottomLeft} />
  </>
);

export const Box = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 40px 114px;
`;

export const PageTitle = styled.h1`
  color: #2E2E2E;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: 0.374px;
  padding: 40px 0px 40px 0px;
`