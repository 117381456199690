
const url = process.env.REACT_APP_API_PATH
const openSeaAPI = process.env.REACT_APP_OPENSEA_API_PATH

export const APIPath = {
    server: url,
    openSeaAPI: openSeaAPI,
    rent: 'rent',
    myrent: 'rent-dashboard',
    lendNFT: 'lend-nft',
    mylend: 'lend-dashboard',
    cancelLending: 'cancel-lending',
    returnNFT: 'return-it',
    claimNFT: 'claim-nft',

    // OpenSea
    assets: 'assets'
}