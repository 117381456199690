import React,{ useMemo } from "react";
import styled from "styled-components";
import { Card,Dropdown,Container } from "semantic-ui-react";
import { UserNFTListHook } from "../hooks";
import { AppListPlaceholder,OpenSeaNFTItem } from "../components";
import { ContainerFull,Header } from "elements";
import { ContractUtility } from "utility";
import { HeaderBar } from "../layout";

const FilterBar = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SmallText = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.374px;
`;

const HeaderTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0px;
`;

const FilterOption = styled(Dropdown)`
  max-width : 300px;
  .text,
  .item {
    overflow-wrap: anywhere;
  }
`

const NFTList = ({ data,onClick }) => (
  <Card.Group itemsPerRow={4} stackable doubling>
    {data.map((item) => (
      <OpenSeaNFTItem
        key={item.id}
        item={item}
      />
    ))}
  </Card.Group>
);

export const MyNFTsScreen = () => {

  // let navigate = useNavigate();

  const addresses = useMemo(() => ContractUtility.getMyAddresses(),[]);

  const { data,loading,filter,filterChanged } = UserNFTListHook();

  const isVIP = useMemo(() => {
    const vip = addresses.find(x => x.text === "VIP");
    return filter?.contract_address === vip?.value
  },[filter?.contract_address])
  const isGeneral = useMemo(() => {
    const general = addresses.find(x => x.text === "General");
    return filter?.contract_address === general?.value
  },[filter?.contract_address])

  const onClick = (item) => {
    // navigate(`/lend-details/${item.token_id}`)
  }

  return (
    <ContainerFull>
      {/* <CornerLogos /> */}
      <HeaderBar />
      <Container className="wrapper">
        <div>
          <Header>
            <HeaderTitle>My NFT Tickets</HeaderTitle>
          </Header>
        </div>
        <FilterBar>
          <div>
            <SmallText>Tickets</SmallText>
          </div>
          <div>
            <FilterOption
              placeholder="Filter by Contact Address"
              selection
              selectOnBlur={false}
              options={addresses}
              value={filter?.contract_address}
              onChange={(_,data) => filterChanged("contract_address",data.value)}
              style={{ width: "200px" }}
              fluid
            />
          </div>
        </FilterBar>

        <div>
          {loading ? (
            <AppListPlaceholder rows={2} itemPerRow={4} />
          ) : (
            <NFTList
              data={data || []}
              onClick={onClick}
            />
          )}
          {!loading && data.length === 0 &&
            <div className="my-4">
              <SmallText>
                You don't have any eligible tickets
                {isGeneral && <a rel="noreferrer" className="ml-1" href="https://galaxy.eco/Dcentralcon/campaign/GCEHmUUMox/" target="_blank">Purchase</a>}
                {isVIP && <span className="ml-1">(Tickets sold out)</span>}
              </SmallText>
            </div>
          }
        </div>
      </Container>
    </ContainerFull>
  );
};
