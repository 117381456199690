console.log("MOde",process.env.REACT_APP_MODE);
export const CommonConstant = {
    mode: process.env.REACT_APP_MODE,
}

export const ErrorConstant = {
    default: "Something went wrong"
}

export const Protocols = {
    ethereum: 'ethereum',
    bsc: 'bsc',
    polygon: 'polygon'
}