import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

const CustomField = styled(Form.Field)`
    padding-bottom: 17px;
    label{
        font-weight: bold;
        font-size: 18px !important;
        color: #6B6B6B !important;
        font-size: 14px !important;
    }
    
    input{
        border: 2px solid ${({ theme }) => theme.colors.border} !important;
        border-radius: 20px !important;
    }
`

export const TextFormField = ({
    label,width,placeholder,required,...rest
}) => (
    <CustomField
        required={required}
        width={width}
    >
        {label && <label>{label}</label>}
        <input
            type="text"
            placeholder={placeholder}
            {...rest}
        />
    </CustomField>
);


export const FormTextFormField = ({
    control,name,defaultValue,label,width,placeholder,errors,required,...rest
}) => (
    <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(props) => (
            <CustomField
                required={required}
                width={width}
                error={errors?.message ? true : false}
            >
                {label && <label>{label}</label>}
                <input
                    type="text"
                    placeholder={placeholder}
                    value={props.field.value}
                    onChange={(e) => props.field.onChange(e.target.value)}
                    {...rest}
                />
            </CustomField>
        )} />
);


export const IntputWrapper = styled.div`
    .vera-input {
        padding-bottom: 17px;
    }
`;


export const SubmitWrapper = styled.div`
    padding-top: 40px;
`;