import React from 'react';
import { Modal,Header } from 'semantic-ui-react';
import { useStore } from '../context/GlobalState';
import { AppPrimaryButton } from 'elements';
import { loadBlockchain } from 'store/asyncActions';

export const ConnectButton = ({ open }) => {

    const [{ protocol },dispatch] = useStore();

    const connectWallet = () => {
        loadBlockchain({ protocol },dispatch);
    }

    return (
        <Modal
            basic
            open={open}
            size='small'
        >
            <Header className="text-center">
                Connect your wallet
            </Header>
            <Modal.Content className="text-center">
                <AppPrimaryButton onClick={connectWallet}>Connect</AppPrimaryButton>
            </Modal.Content>

        </Modal>

    )
}